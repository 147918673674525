import anime from 'animejs';
import Swiper from 'swiper';

export default class Slider {
    news(element, timer, transition) {
        let sliderNews, sliderSlave;

        sliderNews = new Swiper(element, {
            mode: 'horizontal',
            loop: true,
            calculateHeight: true,
            speed: transition,
            autoplay: timer,
            autoplayDisableOnInteraction: false,
            onSwiperCreated: (slider) => {
                document.querySelector(element).children[1].children[2].innerText = '0' + (slider.slides.length - 2);

                const getProgress = document.querySelector(element).children[1].children[1].children[0];
                getProgress.style.transitionDuration = timer + 'ms';
                getProgress.style.width = '100%';
            },
            onSlideChangeStart: (slider) => {
                document.querySelector(element).children[1].children[0].innerText = '0' + (slider.activeLoopIndex + 1);

                const getProgress = document.querySelector(element).children[1].children[1].children[0];
                getProgress.style.left = 'auto';
                getProgress.style.right = '0px';
                getProgress.style.transitionDuration = transition + 'ms';
                getProgress.style.width = '0%';

                setTimeout(() => {
                    sliderSlave.swipeTo(slider.activeLoopIndex);
                }, 200);
            },
            onSlideChangeEnd: (slider) => {
                document.querySelector(element).children[1].children[0].innerText = '0' + (slider.activeLoopIndex + 1);

                const getProgress = document.querySelector(element).children[1].children[1].children[0];
                getProgress.style.right = 'auto';
                getProgress.style.left = '0px';
                getProgress.style.transitionDuration = timer + 'ms';
                getProgress.style.width = '100%';
            },
        });

        const getSlave = document.querySelector(element).nextElementSibling.children[0];

        sliderSlave = new Swiper(getSlave, {
            mode: 'horizontal',
            loop: false,
            speed: transition,
            timer: 0,
            autoplayDisableOnInteraction: false,
            simulateTouch: false,
            onSwiperCreated: (slider) => {
                document.querySelectorAll('.news .images .preview .prev img')[0].src = slider.slides[slider.slides.length - 1].children[0].src;
                document.querySelectorAll('.news .images .preview .next img')[0].src = slider.slides[1].children[0].src;
            },
            onSlideChangeStart: (slider) => {
                let prevImages = document.querySelectorAll('.news .images .preview .prev img');
                let nextImages = document.querySelectorAll('.news .images .preview .next img');

                if (slider.activeIndex !== 0 && slider.activeIndex !== (slider.slides.length - 1)) {
                    prevImages[1].src = slider.slides[slider.activeIndex - 1].children[0].src;
                    nextImages[1].src = slider.slides[slider.activeIndex + 1].children[0].src;
                }

                if (slider.activeIndex === 0) {
                    prevImages[1].src = slider.slides[slider.slides.length - 1].children[0].src;
                    nextImages[1].src = slider.slides[slider.activeIndex + 1].children[0].src;
                }

                if (slider.activeIndex === (slider.slides.length - 1)) {
                    prevImages[1].src = slider.slides[slider.activeIndex - 1].children[0].src;
                    nextImages[1].src = slider.slides[0].children[0].src;
                }

                anime({
                    targets: prevImages[0],
                    duration: 650,
                    easing: 'easeInQuad',
                    translateX: '-100%',
                });
                anime({
                    targets: prevImages[1],
                    duration: 650,
                    easing: 'easeInQuad',
                    translateX: ['100%', 0],
                    complete: () => this.removePrev(),
                });

                anime({
                    targets: nextImages[0],
                    duration: 650,
                    easing: 'easeInQuad',
                    translateX: '-100%',
                });
                anime({
                    targets: nextImages[1],
                    duration: 650,
                    easing: 'easeInQuad',
                    translateX: ['100%', 0],
                    complete: () => this.removeNext(),
                });
            },
        });

        let getControl = document.querySelector(element).parentNode.children[1].children[1];

        getControl.querySelector('.prev').addEventListener('click', function (e) {
            e.stopPropagation();
            sliderNews.swipePrev();
        });

        getControl.querySelector('.next').addEventListener('click', function (e) {
            e.stopPropagation();
            sliderNews.swipeNext();
        });
    }

    removePrev() {
        const getPrev = document.querySelector('.news .images .preview .prev');
        getPrev.removeChild(getPrev.children[0]);
        let newImg = document.createElement('img');
        newImg.setAttribute('src', '');
        getPrev.appendChild(newImg);
    }

    removeNext() {
        const getPrev = document.querySelector('.news .images .preview .next');
        getPrev.removeChild(getPrev.children[0]);
        let newImg = document.createElement('img');
        newImg.setAttribute('src', '');
        getPrev.appendChild(newImg);
    }

    products(element, transition) {
        let nbSlides;
        if (window.innerWidth < 768) {
            nbSlides = 1;
        } else {
            nbSlides = 2;
        }

        let sliderProducts = new Swiper(element, {
            mode: 'horizontal',
            loop: true,
            calculateHeight: true,
            speed: transition,
            autoplay: 0,
            autoplayDisableOnInteraction: false,
            slidesPerView: nbSlides,
        });

        let getControl = document.querySelector(element).children[2];

        getControl.querySelector('.prev').addEventListener('click', function (e) {
            e.stopPropagation();
            sliderProducts.swipePrev();
        });

        getControl.querySelector('.next').addEventListener('click', function (e) {
            e.stopPropagation();
            sliderProducts.swipeNext();
        });
    }

    references(element, transition) {
        let sliderReferences, sliderReferencesSecond, sliderReferencesThird;

        let nbSlides;
        if (window.innerWidth < 768) {
            nbSlides = 1;
        } else if (window.innerWidth < 1024) {
            nbSlides = 2;
        } else {
            nbSlides = 3;
        }

        sliderReferences = new Swiper(element + '-0', {
            mode: 'horizontal',
            loop: false,
            calculateHeight: true,
            speed: transition,
            autoplay: 0,
            slidesPerView: nbSlides,
            simulateTouch: true,
        });

        if (document.querySelector(element + '-1') != null) {
            sliderReferencesSecond = new Swiper(element + '-1', {
                mode: 'horizontal',
                loop: false,
                calculateHeight: true,
                speed: transition,
                autoplay: 0,
                slidesPerView: nbSlides,
                simulateTouch: false,
            });
        }

        if (document.querySelector(element + '-2') != null) {
            sliderReferencesThird = new Swiper(element + '-2', {
                mode: 'horizontal',
                loop: false,
                calculateHeight: true,
                speed: transition,
                autoplay: 0,
                slidesPerView: nbSlides,
                simulateTouch: false,
            });
        }

        document.querySelector('.references .wrapper .controls .prev').addEventListener('click', function () {
            sliderReferences.swipePrev();

            if (document.querySelector(element + '-1') != null) {
                setTimeout(() => {
                    sliderReferencesSecond.swipePrev();
                }, (transition / 6 * 1.5));
            }

            if (document.querySelector(element + '-2') != null) {
                setTimeout(() => {
                    sliderReferencesThird.swipePrev();
                }, (transition / 6 * 3));
            }
        });

        document.querySelector('.references .wrapper .controls .next').addEventListener('click', function () {
            sliderReferences.swipeNext();

            if (document.querySelector(element + '-1') != null) {
                setTimeout(() => {
                    sliderReferencesSecond.swipeNext();
                }, (transition / 6 * 1.5));
            }

            if (document.querySelector(element + '-2') != null) {
                setTimeout(() => {
                    sliderReferencesThird.swipeNext();
                }, (transition / 6 * 3));
            }
        });
    }

    testimonies(element, timer, transition) {
        let sliderTestimonies = new Swiper(element, {
            mode: 'horizontal',
            loop: true,
            calculateHeight: true,
            speed: transition,
            autoplay: timer,
        });
    }
}