import {PerspectiveCamera} from 'three/src/cameras/PerspectiveCamera';
import {BufferAttribute} from 'three/src/core/BufferAttribute';
import {BufferGeometry} from 'three/src/core/BufferGeometry';
import {ShaderMaterial} from 'three/src/materials/ShaderMaterial';
import {Color} from 'three/src/math/Color';
import {Vector3} from 'three/src/math/Vector3';
import {Points} from 'three/src/objects/Points';
import {WebGLRenderer} from 'three/src/renderers/WebGLRenderer';
import {Scene} from 'three/src/scenes/Scene';

export default class Waves {
    constructor() {
        // Page d'accueil

        this.separation = 130;
        this.amountx = 50;
        this.amounty = 50;
        this.speed = 0;

        let header = document.querySelector('header');
        this.heightCanvas = document.body.classList.contains('single-product') ?
            header.offsetHeight + document.querySelector('section .presentation').offsetHeight :
            header.offsetHeight;

        this.init();
        this.animate();
    }

    init() {
        const container = document.querySelector('header');

        this.camera = new PerspectiveCamera(75, window.innerWidth / window.innerHeight, 1, 10000);
        this.camera.position.y = 250;
        this.camera.position.z = 2650;
        this.camera.lookAt(new Vector3(0, 0, 0));
        this.scene = new Scene();

        const numParticles = this.amountx * this.amounty;
        const positions = new Float32Array(numParticles * 3);
        const scales = new Float32Array(numParticles);
        let i = 0, j = 0;
        for (let ix = 0; ix < this.amountx; ix++) {
            for (let iy = 0; iy < this.amounty; iy++) {
                positions[i] = ix * this.separation - ((this.amountx * this.separation) / 2);
                positions[i + 1] = 0;
                positions[i + 2] = iy * this.separation - ((this.amounty * this.separation) / 2);
                scales[j] = 1;
                i += 3;
                j++;
            }
        }

        const geometry = new BufferGeometry();
        geometry.addAttribute('position', new BufferAttribute(positions, 3));
        geometry.addAttribute('scale', new BufferAttribute(scales, 1));
        const material = new ShaderMaterial({
            uniforms: {
                color: {value: new Color(0x1f53e9)},
            },
            vertexShader: document.getElementById('vertexshader').textContent,
            fragmentShader: document.getElementById('fragmentshader').textContent,
        });

        this.particles = new Points(geometry, material);
        this.scene.add(this.particles);

        this.renderer = new WebGLRenderer({antialias: true, alpha: true});
        this.renderer.setPixelRatio(window.devicePixelRatio);
        this.renderer.setSize(window.innerWidth, this.heightCanvas);

        container.appendChild(this.renderer.domElement);
        window.addEventListener('resize', this.onWindowResize.bind(this), false);
    }

    onWindowResize() {
        this.camera.aspect = window.innerWidth / this.heightCanvas;
        this.camera.updateProjectionMatrix();
        this.renderer.setSize(window.innerWidth, this.heightCanvas);
    }

    animate() {
        requestAnimationFrame(this.animate.bind(this));
        this.render();
    }

    render() {
        const positions = this.particles.geometry.attributes.position.array;
        const scales = this.particles.geometry.attributes.scale.array;
        let i = 0, j = 0;
        for (let ix = 0; ix < this.amountx; ix++) {
            for (let iy = 0; iy < this.amounty; iy++) {
                positions[i + 1] = (Math.sin((ix + this.speed) * 0.3) * 50) +
                    (Math.sin((iy + this.speed) * 0.5) * 50);
                scales[j] = (Math.sin((ix + this.speed) * 0.3) + 1) * 8 +
                    (Math.sin((iy + this.speed) * 0.5) + 1) * 8;
                i += 3;
                j++;
            }
        }
        this.particles.geometry.attributes.position.needsUpdate = true;
        this.particles.geometry.attributes.scale.needsUpdate = true;
        this.renderer.render(this.scene, this.camera);
        this.speed += 0.05;
    }
}