export default class Case {
    constructor() {
    	let getTabs = document.querySelectorAll('.case-studies .tabs li');
    	for(let i = 0; i < getTabs.length; i++){
            getTabs[i].addEventListener('click', this.showTab);
        }
    }

    showTab(e){
        let getList = e.target.parentNode.children;
        for(let i = 0; i < getList.length; i++){
            getList[i].classList.remove('active');
        }
        e.target.className += ' active';

    	let getContainers = e.target.parentNode.parentNode.children[2].children;
    	for(let j = 0; j < getContainers.length; j++){
    		if(getContainers[j].dataset.tab == e.target.dataset.target){
    			getContainers[j].className += ' active';
    		} else {
    			getContainers[j].classList.remove('active');
    		}
    	}

    }
}