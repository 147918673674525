export default class Popin {
    constructor() {
    	let getListeners = document.querySelectorAll('.technical .files li');
    	for(let i = 0; i < getListeners.length; i++){
            getListeners[i].addEventListener('click', this.showDoc);
        }

        document.querySelector('.popin-container .close').addEventListener('click', this.hidePopin);
        document.querySelector('.popin-container .overlay').addEventListener('click', this.hidePopin);
    }

    showDoc(e){
        const getPopin = document.querySelector('.popin-container');
        getPopin.classList.add('visible');

        const getDocs = getPopin.querySelectorAll('.popin .wrapper form .documents fieldset');
        for(let j = 0; j < getDocs.length; j++){
            if(getDocs[j].children[0].id == e.target.dataset.document){
                getDocs[j].children[0].checked = 'checked'
            }
        }
    }

    hidePopin(){
        document.querySelector('.popin-container').classList.remove('visible');

        const getDocs = document.querySelectorAll('.popin-container .popin .wrapper form .documents fieldset');
        for(let j = 0; j < getDocs.length; j++){
                getDocs[j].children[0].checked = '';
        }
    }
}