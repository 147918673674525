import objectFitImages from 'object-fit-images';
import 'simplebar';

import Nav from './nav';
import Archive from './renderers/archive';
import Contact from './renderers/contact';
import Home from './renderers/home';
import Product from './renderers/product';
import Rte from './renderers/rte';
import Waves from './waves';

objectFitImages();

document.addEventListener('DOMContentLoaded', () => {
    new Waves();
    new Nav();

    if (document.body.classList.contains('home')) {
        new Home();
    }

    if (document.body.classList.contains('page') && !document.body.classList.contains('page-template-contact') && !document.body.classList.contains('page-template-listing-offre') && !document.body.classList.contains('home') || document.body.classList.contains('single')){
        new Rte();
    }

    if (document.body.classList.contains('single-product')) {
        new Product();
    }

    if (document.body.classList.contains('blog') || document.body.classList.contains('archive') && !document.body.classList.contains('post-type-archive-job_offer')) {
        new Archive();
    }

    if (document.body.classList.contains('page-template-contact')) {
        new Contact();
    }
});