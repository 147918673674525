export default class Contact {
    constructor() {
        this.formWrapper = document.querySelector('.contact form .form-wrapper');
        this.forms = Array.from(document.querySelectorAll('.contact form .form-container'));
        this.globalSubjectSelect = document.querySelector('.contact form fieldset.header select');
        this.firstLocation('.contact .map-container .locations .location.toulouse');

        Array.from(document.querySelectorAll('.contact .map-container .map svg .city'))
            .forEach(elt => elt.addEventListener('click', this.showLocation));

        this.globalSubjectSelect.addEventListener('change', () => this.switchForm());
        this.switchForm();
    }

    firstLocation(selector) {
        let element = document.querySelector(selector);
        if (!element) {
            return;
        }
        element.classList.add('visible');
        const city = element.classList[1];
        document.querySelector('.contact .map-container .map svg #' + city).style.opacity = '1';
    }

    showLocation(e) {
        const allCities = Array.from(document.querySelectorAll('.contact .map-container .locations .location'));
        allCities.forEach(city => {
            city.classList.remove('visible');
        });

        Array.from(document.querySelectorAll('.contact .map-container .map svg .city')).forEach(element => {
            element.style.opacity = '';
        });
        e.target.parentElement.style.opacity = '1';

        // const getTarget = e.target.parentNode.parentNode.classList[1];
        let location = document.querySelector('.contact .map-container .locations .location.' + e.target.parentElement.id);
        if (location) {
            location.classList.add('visible');
        }
        // document.querySelector('.contact .map-container .map svg .city.' + getTarget + ' g').style.opacity = '1';
    }

    switchForm() {
        let activeForm = this.forms.find(elt => elt.classList.contains(this.globalSubjectSelect.value));
        this.formWrapper.innerHTML = activeForm ? activeForm.innerHTML : '';

        const formInputs = document.querySelectorAll('input[type="file"]');
        for (let k = 0; k < formInputs.length; k++) {
            formInputs[k].addEventListener('change', this.editName);
        }
    }

    editName(input) {
        if (input.currentTarget.dataset.maxsize < input.currentTarget.files[0].size) {
            alert('Le fichier est trop volumineux');
            input.currentTarget.value = '';
        } else {
            input.currentTarget.nextElementSibling.innerHTML = '<span class="validate"></span>' + input.currentTarget.files[0].name;
        }
    }
}