import Slider from '../slider';
import Case from '../case-studies';

export default class Rte {
    constructor() {
    	let slider = new Slider();
        if(document.querySelectorAll('.slider-actualites').length > 0){
            slider.news('.slider-actualites', 5000, 500);
        }
        if(document.querySelectorAll('.slider-produits').length > 0){
            slider.products('.slider-produits', 850);
        }

        new Case();

        let videoWrapper = document.querySelectorAll('.video-container');
        for(let i = 0; i < videoWrapper.length; i++){
            videoWrapper[i].querySelector('.btn').addEventListener('click', this.video);
            videoWrapper[i].querySelector('.play').addEventListener('click', this.video);
            videoWrapper[i].querySelector('.close').addEventListener('click', this.removeVideo);
        }
    }

    video(e) {
        let videoContainer = e.currentTarget.parentNode,
            src = videoContainer.dataset.youtube;

        videoContainer.appendChild(document.createElement('iframe'));
        videoContainer.querySelector('iframe').src = src;
        videoContainer.querySelector('iframe').frameBorder = '0';
        videoContainer.className += ' played';
    }

    removeVideo(e){
        let videoContainer = e.currentTarget.parentNode;

        videoContainer.removeChild(videoContainer.querySelector('iframe'));
        videoContainer.classList.remove('played');
    }
}