export default class Archive {
    constructor() {
        this.filters = document.querySelector('header .filters');
        this.button = this.filters.querySelector('div');
        this.form = this.filters.querySelector('form');

        this.button.addEventListener('click', this.displayFilters.bind(this));

        setTimeout( () => {
        	document.querySelector('header .filters form').style.display = 'block';
        }, 500);
    }

    displayFilters() {
        this.form.classList.toggle('visible');
    }
}