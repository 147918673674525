import Case from '../case-studies';
import Popin from '../popin';
import Slider from '../slider';

export default class Product {
    constructor() {
        let slider = new Slider();
        slider.products('.slider-produits', 850);
        slider.testimonies('.slider-temoignage', 5000, 850);

        new Case();
        new Popin();

        document.querySelector('.single-product section .scroll > div').addEventListener('click', this.scrollDown);
    }

    scrollDown() {
        window.scroll(0, document.querySelector('.single-product .content').offsetTop);
    }
}