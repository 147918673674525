import anime from 'animejs';

export default class Nav {
    constructor() {
        this.lastScroll = window.pageYOffset;
        this.nav = document.querySelector('nav');

        this.nav.querySelector('.butons .search svg').addEventListener('click', this.showForm);
        this.nav.querySelector('.butons .search .overlay').addEventListener('click', this.hideForm);
        document.querySelector('.toggle-tarte').addEventListener('click', e => {
            e.preventDefault();
            if ('tarteaucitron' in window) {
                tarteaucitron.userInterface.openPanel();
            }
        });

        Array.from(document.querySelectorAll('.scroll'))
            .forEach(elt => elt.addEventListener('click', this.scrollDown));

        Array.from(this.nav.querySelectorAll('a[href="#"]'))
            .forEach(anchor => anchor.addEventListener('click', event => event.preventDefault()));

        window.addEventListener('scroll', () => {
            this.nav.classList.toggle('hidden', this.lastScroll < window.pageYOffset && window.pageYOffset > 0);
            this.nav.classList.toggle('small', window.pageYOffset > 0);

            this.lastScroll = window.pageYOffset;
        }, {passive: true});

        document.querySelector('nav .btn-burger').addEventListener('click', this.switchNav);

        if(window.innerWidth < 1025){
            let mobileLinks = document.querySelectorAll('nav .menu ul li.menu-item-has-children > a');
            for(let i = 0; i < mobileLinks.length; i++){
                mobileLinks[i].addEventListener('click', (e) => {
                    e.preventDefault();
                    e.currentTarget.nextElementSibling.classList.toggle('visible');
                });
            }
        }
    }

    showForm(e) {
        if (!e.currentTarget.parentNode.classList.contains('visible')) {
            e.target.parentNode.className += ' visible';
            document.querySelector('nav.row .menu').className += ' hidden';

            anime({
                targets: e.currentTarget.nextElementSibling,
                duration: 650,
                easing: 'easeInQuad',
                width: [0, 350],
            });
            document.querySelector('nav.row .butons .search form input').focus();
        } else {
            document.forms['search'].submit();
        }
    }

    hideForm(e) {
        e.currentTarget.parentNode.classList.remove('visible');
        document.querySelector('nav.row .menu').classList.remove('hidden');
        anime({
            targets: e.currentTarget.previousElementSibling,
            duration: 650,
            easing: 'easeInQuad',
            width: [350, 0],
        });
    }

    scrollDown(e) {
        window.scrollTo({
            top: window.innerHeight,
            behavior: 'smooth',
        });
    }

    switchNav(e){
        e.currentTarget.classList.toggle('open');
        e.currentTarget.parentNode.classList.toggle('visible');
        document.querySelector('html').classList.toggle('overflow');
    }
}